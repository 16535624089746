import React, { useEffect, useRef, useState } from "react";
import BackButton from "../components/microComponents/BackButton";
import { FaBars } from "react-icons/fa";
import { BiLeftArrow, BiRightArrow } from "react-icons/bi";
import { AiOutlineRetweet } from "react-icons/ai";
import { SlVolume2, SlClose } from "react-icons/sl";
import { FaThumbsDown, FaThumbsUp, FaBookOpen } from "react-icons/fa";
import ShowAllQuestion from "../components/ShowAllQuestion/ShowAllQuestion";
import exam_board from "../assets/exam_borad_logo.png";
import facSimile from "../assets/fac_simile.png";
import scheda from "../assets/scheda_esame.png";
import logo from "../assets/logo.png";
import TimerView, { TimerViewRef } from "../components/TimerView/TimerView";
import { useNavigate, useParams } from "react-router-dom";
import Urls from "../api/Urls";
import AnswerBtn from "../components/AnswerBtn/AnswerBtn";
import QuestionBtn from "../components/QuestionBtn/QuestionBtn";
import QuestionSpan from "../components/QuestionSpan/QuestionSpan";
import Utils from "../utils/Utils";
import scrollToTop from "../components/ScrollTop/ScrollTop";
import useUserStore from "../stores/useUserStore";
import CourseApi from "../api/services/CourseApi";
import useCourseStore from "../stores/useCourseStore";
import manik from "../assets/manik.png";
import { Swiper, SwiperSlide } from "swiper/react";
import Skeleton from "react-loading-skeleton";
import { Modal } from "react-bootstrap";
import ZoomModal from "../components/microComponents/ZoomModal";

let currentAudio: any = null;

export interface Props {}

export const QuizExam: React.FC<Props> = (props) => {
  const { type } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const timerRef = useRef<TimerViewRef>(null);
  const user = useUserStore((state) => state.user);
  const originalQuizList = useCourseStore((state) => state.originalQuizList);
  const setOriginalQuizList = useCourseStore(
    (state) => state.setOriginalQuizList
  );
  const quizList = useCourseStore((state) => state.quizList);
  const setQuizList = useCourseStore((state) => state.setQuizList);
  const capitoliIds = useCourseStore((state) => state.capitoliIds);
  // const [showIndicator, setShowIndicator] = useState(false);
  const [showQuizListView, setShowQuizListView] = useState(false);
  const [showResultView, setShowResultView] = useState(false);
  const [isExamClosed, setIsExamClosed] = useState(false);
  const [selectedQuizIndex, setselectedQuizIndex] = useState(-1);
  const [wrongAnswer, setWrongAnswer] = useState(0);
  const [textList, setTextList] = useState<any[]>([]);
  // const [translateText, setTranslateText] = useState("");
  // const [showTranslatePopover, setShowTranslatePopover] = useState(false);
  const quizBoxRef = useRef(null);
  const [showTimeExtenderModal, setShowTimeExtenderModal] = useState(false);

  useEffect(() => {
    // scrollToTop();
    // Scroll the window to the top of the quiz_box element
    if (quizBoxRef.current) {
      (quizBoxRef.current as HTMLDivElement).scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }

    fetchApiQuiz();

    return () => {
      window.speechSynthesis.cancel();
    };
  }, []);

  useEffect(() => {
    if (selectedQuizIndex >= 0 && quizList.length > 0) {
      let test = quizList[selectedQuizIndex]?.question;
      setTextList(test?.split(" "));
      // scrollRef.current?.scrollTo({x: selectedQuizIndex * 40});
    }
  }, [selectedQuizIndex, quizList]);

  const fetchApiQuiz = () => {
    if (type === "CUSTOM") {
      setselectedQuizIndex(0);
      setWrongAnswer(0);
      setIsExamClosed(false);
      setShowResultView(false);
      setShowQuizListView(false);
      if (timerRef.current) {
        timerRef?.current.resetTimer();
        timerRef?.current.startTimer();
      }
    } else if (type === "SIMULATION") {
      setIsLoading(true);
      CourseApi.getFinalQuizzes({
        onSuccess: (response) => {
          console.log("response", response);
          const formatedQuize = response.data.reduce(
            (result: any[], item: any) => [
              ...result,
              { ...item, answer: item.answer === "1" ? true : false },
            ],
            []
          );
          setOriginalQuizList(formatedQuize);
          setQuizList(JSON.parse(JSON.stringify(formatedQuize)));
          setselectedQuizIndex(0);
          setWrongAnswer(0);
          setIsLoading(false);
          setIsExamClosed(false);
          setShowResultView(false);
          setShowQuizListView(false);

          setTimeout(() => {
            if (timerRef.current) {
              timerRef?.current.resetTimer();
              timerRef?.current.startTimer();
            }
          }, 1000);
        },
        onError: () => setIsLoading(false),
      });
    } else if (type === "CAPITOLI") {
      setIsLoading(true);
      CourseApi.getChaptersQuizzes(
        { chapter: capitoliIds },
        {
          onSuccess: (response) => {
            // console.log("getChaptersQuizzes", response);
            const formatedQuize = response.data.reduce(
              (result: any[], item: any) => [
                ...result,
                { ...item, answer: item.answer === "1" ? true : false },
              ],
              []
            );
            setOriginalQuizList(formatedQuize);
            setQuizList(JSON.parse(JSON.stringify(formatedQuize)));
            setselectedQuizIndex(0);
            setWrongAnswer(0);
            setIsLoading(false);
            setIsExamClosed(false);
            setShowResultView(false);
            setShowQuizListView(false);

            setTimeout(() => {
              if (timerRef.current) {
                timerRef?.current.resetTimer();
                timerRef?.current.startTimer();
              }
            }, 1000);
          },
          onError: () => setIsLoading(false),
        }
      );
    }
  };

  const onAnswerPressed = (answer: boolean) => {
    if (selectedQuizIndex < quizList.length) {
      const qesList = [...quizList];
      qesList[selectedQuizIndex].user_answer = answer;
      setQuizList(qesList);

      if (type !== "SIMULATION") {
        onNextPressed();
      }
    }
  };

  const onNextPressed = () => {
    if (selectedQuizIndex < quizList.length - 1) {
      setselectedQuizIndex(selectedQuizIndex + 1);
      window.speechSynthesis.cancel();
    }
  };

  const onPreviousPressed = () => {
    if (selectedQuizIndex > 0) {
      setselectedQuizIndex(selectedQuizIndex - 1);
      window.speechSynthesis.cancel();
    }
  };

  const onExamClosePressed = () => {
    const result = window.confirm(
      "Close exam? Are you sure to close the exam and see the result screen?"
    );

    if (result) {
      closeExam();
    }
  };

  const onPressedNewTab = () => {
    setselectedQuizIndex(-1);
    fetchApiQuiz();
  };

  const onRepeatPress = () => {
    setQuizList(JSON.parse(JSON.stringify(originalQuizList)));
    setselectedQuizIndex(0);
    setWrongAnswer(0);
    setIsExamClosed(false);
    setShowResultView(false);
    setShowQuizListView(false);
    if (timerRef.current) {
      timerRef?.current.resetTimer();
      timerRef?.current.startTimer();
    }
  };

  const closeExam = () => {
    filterChapterWiseResult([...quizList]);

    let makeAllAnswer = quizList.reduce((result, item) => {
      if (item.user_answer === undefined) {
        return [...result, { ...item, user_answer: !item.answer }];
      } else {
        return [...result, { ...item }];
      }
    }, []);

    const wrongAnswer = makeAllAnswer.filter(
      (item: any) => item.user_answer !== item.answer
    );

    setQuizList(makeAllAnswer);
    setIsExamClosed(true);
    setShowResultView(true);
    setWrongAnswer(wrongAnswer.length);
    if (timerRef.current) {
      timerRef?.current.stopTimer();
    }
  };

  const filterChapterWiseResult = (results: any[]) => {
    const examResult = results.reduce((newResult: any[], obj) => {
      if (obj.user_answer !== undefined) {
        return [
          ...newResult,
          {
            quiz_id: obj.id,
            chapter_id: obj.chapter_id,
            correct_answer: obj.user_answer === obj.answer ? 1 : 0,
          },
        ];
      } else {
        return newResult;
      }
    }, []);

    // console.log("examResult", examResult);
    CourseApi.saveExamResult(
      { payload: examResult }
      // {
      //   showIndicator: true,
      //   onSuccess: response =>
      //     console.log(' saveExamResult response', response),
      //   onError: onError => console.log(' saveExamResult onError', onError),
      // },
    );
  };

  const getShowResult = (quiz: any) => {
    let result = false;

    if (type === "SIMULATION") {
      result = isExamClosed;
    } else {
      result = quiz?.user_answer !== undefined ? true : false;
    }

    return result;
  };

  const getAnswerMood = () => {
    let mood: "SELECTION" | "ANSWERED" = "SELECTION";
    if (type === "SIMULATION") {
      mood = isExamClosed ? "ANSWERED" : "SELECTION";
    } else {
      mood =
        quizList[selectedQuizIndex]?.user_answer !== undefined
          ? "ANSWERED"
          : "SELECTION";
    }
    return mood;
  };

  const playAudio = (playUrl: string) => {
    if (currentAudio) {
      currentAudio.pause();
    }

    // console.log("audioUrl", Urls.storagePath + playUrl);
    const audio = new Audio(Urls.storagePath + playUrl);
    audio.play();
    currentAudio = audio;
  };

  // console.log("originalQuizList", originalQuizList);
  return (
    <div className="Container pb-4">
      <BackButton title="Quiz" />
      {isLoading ? (
        skeleton
      ) : (
        <div className="quiz_page_main">
          <div className="quiz_box" ref={quizBoxRef}>
            <div className="qTopSec">
              <div className="exam_board">
                <img src={exam_board} alt="" />
                <span className="q_left_text">
                  <span className="mainText">
                    Ministero delle Infrastrutture <br />
                  </span>
                  <span>e dei Trasporti</span>
                  <span className="extra_text">
                    Dipartimento per i Trasporti, la Navigazione edi Sistemi
                    Informatii e Statistic Direzione Generale per la
                    Motorizzazione
                  </span>
                </span>
              </div>
              <div className="qT_img">
                <img src={scheda} alt="" />
              </div>
              <div className="qT_img">
                <img src={facSimile} alt="" />
              </div>
              <div className="qT_img">
                <img src={logo} alt="" />
              </div>
            </div>

            {/* large btn section */}
            <div className="domande_box">
              <div
                onClick={() => setselectedQuizIndex(0)}
                className={`dom_sec ${
                  selectedQuizIndex >= 0 && selectedQuizIndex < 10
                    ? "dom_sec_active"
                    : ""
                }`}
              >
                <span>Domande</span>
                <br />
                <span className="dom_sec_serial">da 1 a 10</span>
              </div>
              <div
                onClick={() => {
                  if (quizList.length > 10) {
                    setselectedQuizIndex(10);
                  }
                }}
                className={`dom_sec ${
                  selectedQuizIndex >= 10 && selectedQuizIndex < 20
                    ? "dom_sec_active"
                    : ""
                }`}
              >
                <span>Domande</span>
                <br />
                <span className="dom_sec_serial">da 11 a 20</span>
              </div>
              <div
                onClick={() => {
                  if (quizList.length > 20) {
                    setselectedQuizIndex(20);
                  }
                }}
                className={`dom_sec ${
                  selectedQuizIndex >= 20 && selectedQuizIndex < 30
                    ? "dom_sec_active"
                    : ""
                }`}
              >
                <span>Domande</span>
                <br />
                <span className="dom_sec_serial">da 21 a 30</span>
              </div>
            </div>

            {/* small static btn section */}
            <div className="page_serial">
              {quizList.map((item, index) => {
                if (
                  (selectedQuizIndex >= 0 &&
                    selectedQuizIndex < 10 &&
                    index >= 0 &&
                    index < 10) ||
                  (selectedQuizIndex >= 10 &&
                    selectedQuizIndex < 20 &&
                    index >= 10 &&
                    index < 20) ||
                  (selectedQuizIndex >= 20 &&
                    selectedQuizIndex < 30 &&
                    index >= 20 &&
                    index < 30)
                ) {
                  return (
                    <QuestionBtn
                      key={index}
                      onClick={() => setselectedQuizIndex(index)}
                      name={(index + 1).toString()}
                      type={type}
                      isSelected={index === +selectedQuizIndex}
                      answer={quizList[index].answer}
                      isAnswered={quizList[index].user_answer}
                      showResult={getShowResult(quizList[index])}
                    />
                  );
                }
                return null;
              })}
            </div>

            <div className="quiz_serial">
              <Swiper
                slidesPerView={"auto"}
                spaceBetween={10}
                draggable={true}
                pagination={{
                  clickable: true,
                }}
              >
                {quizList.map((item, index) => (
                  <SwiperSlide
                    key={index}
                    style={{ width: "20px", marginRight: "5px" }}
                  >
                    {" "}
                    {/* Adjust slide width as needed */}
                    <QuestionBtn
                      onClick={() => setselectedQuizIndex(index)}
                      name={(index + 1).toString()}
                      type={type}
                      isSelected={index === selectedQuizIndex}
                      answer={quizList[index].answer}
                      isAnswered={quizList[index].user_answer}
                      showResult={getShowResult(quizList[index])}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>

            {showQuizListView ? (
              <div>
                <div className="answer_area">
                  <ShowAllQuestion
                    type={type}
                    questions={quizList}
                    isExamClosed={isExamClosed}
                  />
                </div>
              </div>
            ) : (
              <div className="quiz_area">
                <div className="left_area">
                  <div className="img">
                    <ZoomModal
                      img={
                        Urls?.storagePath +
                        quizList[selectedQuizIndex]?.media?.file_path
                      }
                    >
                      {quizList[selectedQuizIndex]?.media?.file_path && (
                        <img
                          src={
                            Urls.storagePath +
                            quizList[selectedQuizIndex]?.media?.file_path
                          }
                          alt=""
                        />
                      )}
                    </ZoomModal>
                  </div>
                </div>

                <div className="right_area">
                  <div className="q_info right_area_uper">
                    <div className="quiz_info">
                      {quizList.length > 0 && (
                        <div className="buttons">
                          <button
                            onClick={() =>
                              Utils.translateFromApi(
                                quizList[selectedQuizIndex]?.question,
                                "bn"
                              )
                            }
                            className="btnr warning sm"
                          >
                            বাংলা
                          </button>
                          <button
                            onClick={() =>
                              Utils.translateFromApi(
                                quizList[selectedQuizIndex]?.question,
                                "en"
                              )
                            }
                            className="btnr blue sm"
                          >
                            English
                          </button>

                          {quizList[selectedQuizIndex]?.quiz_voices && (
                            <div
                              className="img"
                              onClick={() =>
                                playAudio(
                                  quizList[selectedQuizIndex]?.quiz_voices
                                )
                              }
                            >
                              <img src={manik} alt="" />
                            </div>
                          )}
                        </div>
                      )}

                      <div className="infos">
                        <span>Domanda numero</span>
                        <span className="serial_quiz">
                          {selectedQuizIndex + 1}
                        </span>
                      </div>
                    </div>

                    {/* question word */}
                    <div className="question_box">
                      <div className="question">
                        {textList.map((word, index) => (
                          // <span
                          //   onClick={(event) => wordTranslateFromApi(word)}
                          //   key={index}
                          // >
                          //   {`${word}`} &nbsp;
                          // </span>

                          <QuestionSpan key={index} question={word} />
                        ))}
                        <span
                          style={{ marginLeft: 20, color: "red" }}
                        >{`QId:${quizList[selectedQuizIndex]?.id}, SId:${quizList[selectedQuizIndex]?.section_id}`}</span>
                        {/* {showIndicator && (
                        <span className="indicator">
                          <div className="loading_circle_btn"></div>
                        </span>
                      )}
                      {showTranslatePopover && (
                        <span className="indicator full">{translateText}</span>
                      )} */}
                      </div>
                    </div>
                  </div>

                  <div className="right_area_bottom">
                    <AnswerBtn
                      type="VERO"
                      examType={type === "SIMULATION" ? "SIMULATION" : "OTHERS"}
                      mood={getAnswerMood()}
                      userAnswer={quizList[selectedQuizIndex]?.user_answer}
                      ignoreSelection={
                        quizList[selectedQuizIndex]?.user_answer === false
                      }
                      correctAnswer={quizList[selectedQuizIndex]?.answer}
                      onClick={() => onAnswerPressed(true)}
                    />
                    <AnswerBtn
                      type="FALSO"
                      examType={type === "SIMULATION" ? "SIMULATION" : "OTHERS"}
                      mood={getAnswerMood()}
                      userAnswer={quizList[selectedQuizIndex]?.user_answer}
                      ignoreSelection={
                        quizList[selectedQuizIndex]?.user_answer === true
                      }
                      correctAnswer={quizList[selectedQuizIndex]?.answer}
                      onClick={() => onAnswerPressed(false)}
                    />
                  </div>
                </div>
              </div>
            )}

            <div className="submit_area">
              <TimerView
                ref={timerRef}
                onTimerEnd={
                  type == "SIMULATION"
                    ? closeExam
                    : () => {
                        if (timerRef.current) {
                          timerRef?.current.stopTimer();
                        }
                        setShowTimeExtenderModal(true);
                      }
                }
              />

              <div className="uper_scheda">
                {/* name and exam number */}
                <div className="Scheda_box">
                  <div>
                    <span>Cognome e Nome del Candidato</span>
                    <span>{`${user?.name}`}</span>
                  </div>
                </div>

                {/* icons view */}
                <div className="click_box">
                  <div className="clk_box">
                    <div className="sub_click_box">
                      {(type !== "SIMULATION" ||
                        (type === "SIMULATION" && isExamClosed)) && (
                        <div>
                          <FaBookOpen
                            className="click_icons"
                            onClick={() =>
                              navigate(
                                `/section/${quizList[selectedQuizIndex]?.section_id}`
                              )
                            }
                          />
                        </div>
                      )}

                      <div>
                        <SlVolume2
                          className="click_icons"
                          onClick={() => {
                            if (quizList[selectedQuizIndex]?.quiz_audio) {
                              playAudio(
                                quizList[selectedQuizIndex]?.quiz_audio
                              );
                            }
                          }}
                        />
                      </div>
                      <div>
                        <FaBars
                          className="click_icons"
                          onClick={() => setShowQuizListView(!showQuizListView)}
                        />
                      </div>
                      <div>
                        <BiLeftArrow
                          className="click_icons"
                          onClick={onPreviousPressed}
                        />
                      </div>
                      <div>
                        <BiRightArrow
                          className="click_icons"
                          onClick={onNextPressed}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="finish_btn">
                    {isExamClosed && (
                      <AiOutlineRetweet
                        className="repeat_icon"
                        onClick={onRepeatPress}
                      />
                    )}
                    <button
                      className="chiudi_btn"
                      onClick={
                        isExamClosed ? onPressedNewTab : onExamClosePressed
                      }
                    >
                      {isExamClosed ? "NUOVA SCHEDA" : "Chiudi Esame"}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="sb_mobile">
              {/* <div className="divBox">
              <span>Scheda Esame N.</span>
              <span>723</span>
            </div> */}
              <div className="divBox">
                <span>Cognome e Nome del Candidato</span>
                <span>{`${user?.name}`}</span>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* tempo modal  */}
      <Modal
        show={showTimeExtenderModal}
        onHide={() => {
          setShowTimeExtenderModal(false);
          closeExam();
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="tempoModal-main"
      >
        <div className="tempoModal">
          <p>
            Tempo disponibile esaurito. Se si fosse in esame la scheda verrebbe
            chiusa, in automatico, e tutti i quesiti a cui non si e risposto
            verrrebbero contati come errore. <br /> <br /> Ma dato che Iattuale
            scheda non simula un eseme, viene concesso di proseguire.
          </p>
          <button
            onClick={() => {
              setShowTimeExtenderModal(false);
              if (timerRef.current) {
                timerRef?.current.resetTimer();
                timerRef?.current.startTimer();
              }
            }}
            className="btnr primary w-100"
          >
            Ok
          </button>
        </div>
      </Modal>

      <Modal
        show={showResultView}
        onHide={() => setShowResultView(false)}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="exam_modal">
          {type !== "CUSTOM" && (
            <>
              <h2>{wrongAnswer > 3 ? "Non Superato!" : "Superato!"}</h2>
              <span>Hai fatto {wrongAnswer} errori</span>
              {wrongAnswer > 3 ? (
                <FaThumbsDown className="the_icon red_ans" />
              ) : (
                <FaThumbsUp className={`the_icon green_ans`} />
              )}
            </>
          )}

          <button
            onClick={() => setShowResultView(false)}
            className="btnr primary w-100"
          >
            RITORNA ALLE DOMANDE
          </button>
          <button onClick={onRepeatPress} className="btnr purple w-100">
            RIPTILA SCHEDA
          </button>
          <button onClick={onPressedNewTab} className="btnr w-100">
            NUOVA SCHEDA
          </button>
        </div>
      </Modal>
    </div>
  );
};

const skeleton = (
  <>
    <div className="quiz_page_main skeleton">
      <div className="quiz_box">
        <div className="qTopSec">
          <div className="exam_board">
            <img src={exam_board} alt="" />
            <span className="q_left_text">
              <span className="mainText">
                Ministero delle Infrastrutture <br />
              </span>
              <span>e dei Trasporti</span>
              <span className="extra_text">
                Dipartimento per i Trasporti, la Navigazione edi Sistemi
                Informatii e Statistic Direzione Generale per la Motorizzazione
              </span>
            </span>
          </div>
          <div className="qT_img">
            <img src={scheda} alt="" />
          </div>
          <div className="qT_img">
            <img src={facSimile} alt="" />
          </div>
          <div className="qT_img">
            <img src={logo} alt="" />
          </div>
        </div>

        <div className="domande_box">
          <div className="dom_sec">
            <Skeleton />
            <Skeleton />
          </div>
          <div className="dom_sec">
            <Skeleton />
            <Skeleton />
          </div>
          <div className="dom_sec">
            <Skeleton />
            <Skeleton />
          </div>
        </div>

        <div className="page_serial">
          {Array.from({ length: 10 }).map((_, index) => (
            <Skeleton key={index} style={{ marginRight: "5px" }} />
          ))}
        </div>

        <div className="quiz_serial">
          <Swiper
            slidesPerView={"auto"}
            spaceBetween={10}
            draggable={true}
            pagination={{
              clickable: true,
            }}
          >
            {Array.from({ length: 20 }).map((_, index) => (
              <SwiperSlide key={index} style={{ width: "30px" }}>
                <Skeleton key={index} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        <div className="quiz_area">
          <div className="left_area">
            <div className="img">
              <Skeleton width="100%" height="100%" />
            </div>
          </div>

          <div className="right_area">
            <div className="q_info right_area_uper">
              <div className="quiz_info">
                <div className="buttons">
                  <Skeleton width={100} height={20} />
                  <Skeleton width={100} height={20} />

                  <div className="img">
                    <Skeleton circle={true} width={30} height={30} />
                  </div>
                </div>
              </div>

              {/* question word */}
              <div className="question" style={{ width: "100%" }}>
                {[...Array(6)].map((_, index) => (
                  <Skeleton width="100%" height={18} key={index} />
                ))}
                <Skeleton width="60%" height={18} />
              </div>
            </div>

            <div className="right_area_bottom">
              <Skeleton width={60} height={70} />
              <Skeleton width={60} height={70} />
            </div>
          </div>
        </div>

        <div className="submit_area">
          <div className="tempo_box">
            <div className="tempo">
              <span>
                <Skeleton width="100%" />
              </span>
              <span>
                <Skeleton width="100%" />
              </span>
            </div>
          </div>

          <div className="uper_scheda">
            {/* name and exam number */}
            <div className="Scheda_box">
              {/* <div>
                <span>Scheda Esame N.</span>
                <span>723</span>
              </div> */}
              <div>
                <span>
                  {" "}
                  <span>
                    <Skeleton width="100%" height={18} />
                  </span>
                </span>
                <span>
                  {" "}
                  <span>
                    <Skeleton width="50%" />
                  </span>
                </span>
              </div>
            </div>

            <div className="click_box">
              <div className="clk_box">
                <div className="sub_click_box skeleton">
                  <div>
                    <Skeleton width={45} height={45} circle={true} />
                  </div>
                  <div>
                    <Skeleton width={45} height={45} circle={true} />
                  </div>
                  <div>
                    <Skeleton width={45} height={45} circle={true} />
                  </div>
                  <div>
                    <Skeleton width={45} height={45} circle={true} />
                  </div>
                </div>
              </div>
              <div className="Scheda_box">
                <div>
                  <span style={{ background: "none" }}>
                    {" "}
                    <span>
                      <Skeleton width="100%" height={22} />
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="sb_mobile">
          <div className="divBox">
            <span>
              {" "}
              <span>
                <Skeleton width="100%" height={18} />
              </span>
            </span>
            <br />
            <span>
              {" "}
              <span>
                <Skeleton width="50%" />
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </>
);

export default QuizExam;
