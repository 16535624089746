import Speakers from "../Icons/Speakers";
import manik from "../../assets/manik.png";
import Utils from "../../utils/Utils";
import QuestionSpan from "../QuestionSpan/QuestionSpan";
export interface Props {
  quizNo: number;
  sectionId?: number;
  question: string;
  answer: boolean;
  audio: string;
  quizVoice?: string;
  playAudio: (url: string) => void;
}

/**
 * This function returns the {@link QuizCard} `component`
 *
 * @param props recieve `Props`
 * @returns `Element`
 */

export const QuizCard: React.FC<Props> = (props) => {
  const { quizNo, sectionId, question, answer, audio, quizVoice, playAudio } =
    props;

  const onAudioSpeakPress = () => {
    const regex = new RegExp(`.{1,20}`, "g");
    const chunks = question.match(regex);
    let currentChunkIndex = 0;

    function speakChunk() {
      if (chunks && currentChunkIndex < chunks.length) {
        const utterance = new SpeechSynthesisUtterance(
          chunks[currentChunkIndex]
        );
        const voices = window.speechSynthesis
          .getVoices()
          .find((item) => item.voiceURI === "Google italiano");
        if (voices) utterance.voice = voices;
        utterance.lang = "it-IT";
        utterance.rate = 1;
        utterance.text = question;

        utterance.onend = function () {
          currentChunkIndex++;
          speakChunk(); // Recursively speak the next chunk
        };

        utterance.onerror = function (event) {
          console.error("Speech error", event);
        };

        window.speechSynthesis.speak(utterance);
      }
    }

    speakChunk();
  };

  // console.log("audio", audio);
  return (
    <div className="stradaItem">
      <div className="upperSec">
        <div className="count">{quizNo}</div>
        <div className="count">{sectionId}</div>
        <div className="actions">
          <div className="buttons">
            <button
              onClick={() => Utils.translateFromApi(question, "bn")}
              className="btnr sm warning"
            >
              বাংলা
            </button>
            <button
              onClick={() => Utils.translateFromApi(question, "en")}
              className="btnr sm blue"
            >
              English
            </button>
          </div>

          {audio && (
            <span className="icon" onClick={() => playAudio(audio)}>
              <Speakers />
            </span>
          )}

          {quizVoice && (
            <div className="img" onClick={() => playAudio(quizVoice)}>
              <img src={manik} alt="" />
            </div>
          )}
        </div>
      </div>

      <div className="info">
        <div className="topInfo">
          {Utils.splitWordsWithLineBreaks(question).map((item, index) => (
            <span className="question" key={index}>
              {item.type === "lineBreak" ? (
                item.content
                  .match(/\n/g)
                  ?.map((line, index) => <br key={index} />)
              ) : (
                <QuestionSpan question={item.content} isSpace={false} />
              )}
            </span>
          ))}

          {/* <span>{question}</span> */}
        </div>

        <div className={`answer ${!answer && "falseColor"}`}>
          {answer ? "V" : "F"}
        </div>
      </div>
    </div>
  );
};

export default QuizCard;
